import Vue from 'vue'
import Vuex from 'vuex'
// import { getFamily } from '../services/home'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 0
  },
  mutations: {
    add(state) {
      state.count++
    }
  }
  // state: {
  //   websock: null,
  //   currentId: 0,
  //   isHome: true,
  //   allMsgList: []
  // },
  // mutations: {
  //   set_websock(state, e) {
  //     state.websock = e
  //   },
  //   addUnred(state, { id }) {
  //     id -= 0
  //     if (state.isHome || state.currentId !== id) {
  //       console.log('添加', state.currentId, id)
  //       const index = state.allMsgList.findIndex(msg => msg.id === id)
  //       state.allMsgList[index].unreadmsg += 1
  //     }
  //   },
  //   setCurrentID(state, id) {
  //     state.currentId = id - 0
  //   },
  //   clearUnreadmsg(state, { id }) {
  //     id -= 0
  //     const index = state.allMsgList.findIndex(msg => msg.id === id)
  //     state.allMsgList[index].unreadmsg = 0
  //   },
  //   toHome(state) {
  //     state.isHome = true
  //   },
  //   outHome(state) {
  //     state.isHome = false
  //   },
  //   init(state, { list }) {
  //     state.isHome = true
  //     state.currentId = 0
  //     const stateAllMsgList = list.map(({ id }) => {
  //       return {
  //         id,
  //         unreadmsg: 0
  //       }
  //     })
  //     const localData = JSON.parse(localStorage.getItem('msg_un'))
  //     if (localData) {
  //       const { allMsgList } = localData
  //       // console.log(allMsgList)
  //       allMsgList.forEach(({ id, unreadmsg }) => {
  //         const target = stateAllMsgList.find(item => item.id === id)
  //         target.unreadmsg = unreadmsg
  //       })

  //       state.allMsgList = stateAllMsgList
  //       // console.log(stateAllMsgList, 'lca')
  //       return false
  //     }

  //     state.allMsgList = stateAllMsgList
  //     console.log(stateAllMsgList, 'no_lca')
  //   },
  //   setLocal({ allMsgList }) {
  //     const data = JSON.stringify({ allMsgList })
  //     localStorage.setItem('msg_un', data)
  //   }
  // },
  // getters: {
  //   getUnreadmsg: state => id => {
  //     const { unreadmsg } = state.allMsgList.find(msg => msg.id === id)
  //     return unreadmsg
  //   }
  // },
  // actions: {
  //   // getFO({ commit }) {
  //   //   const m = JSON.parse(localStorage.getItem('mine'))
  //   //   getFamily(m.id).then(res => {
  //   //     console.log(res.data.data)
  //   //   })
  //   // }
  // },
  // modules: {}
})
