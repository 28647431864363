import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  // 详情页1
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue')
  },
  // 详情页2
  {
    path: '/detail2',
    name: 'detail2',
    component: () => import('../views/detail2.vue')
  },
  //  订单页
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue')
  },
  //  订单页分页
  {
    path: '/order1',
    name: 'order1',
    component: () => import('../views/order1.vue'),
    meta: {
      title: '挂号记录'
    }
  },
  // 我的积分
  {
    path: '/score',
    name: 'score',
    component: () => import('../views/score.vue')
  },
  // 申请单报告单
  {
    path: '/people',
    name: 'people',
    component: () => import('../views/people.vue')
  },
  // 申请单
  {
    path: '/people-apply',
    name: 'people-apply',
    component: () => import('../views/people-apply.vue'),
    meta: {
      title: '申请单'
    }
  },
  // 报告单
  {
    path: '/people-report',
    name: 'people-report',
    component: () => import('../views/people-report.vue'),
    meta: {
      title: '报告单'
    }
  },
  // 对话
  {
    path: '/dialog',
    name: 'dialog',
    component: () => import('../views/dialog.vue')
  },
  // 上传
  {
    path: '/doctor',
    name: 'doctor',
    component: () => import('../views/doctor.vue')
  },
  // 订单详情
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/orderDetail.vue')
  },
  // 优惠券
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('../views/coupon.vue')
  },
  // 账户余额
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/account.vue')
  },
  // 家庭联系人
  {
    path: '/family',
    name: 'family',
    component: () => import('../views/family.vue')
  },
  // 家庭申请单
  {
    path: '/family-apply',
    name: 'family-apply',
    component: () => import('../views/family-apply.vue'),
    meta: {
      title: '申请单'
    }
  },
  // 家庭报告单
  {
    path: '/family-report',
    name: 'family-report',
    component: () => import('../views/family-report.vue'),
    meta: {
      title: '报告单'
    }
  },
  // baogaoDetail
  {
    path: '/baogaoDetail',
    name: 'baogaoDetail',
    component: () => import('../views/baogaoDetail.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/upload.vue')
  },
  // 医院列表
  {
    path: '/hospital',
    name: 'hospital',
    component: () => import('../views/hospital.vue')
  },
  // vuex 测试页面
  {
    path: '/vuexTest',
    name: 'vuexTest',
    component: () => import('../views/vuexTest.vue')
  },
  // 邀请人列表
  {
    path: '/inviteList',
    name: 'inviteList',
    component: () => import('../views/inviteList.vue')
  },
  // 列表详情
  {
    path: '/hospitalDetail',
    name: 'hospitalDetail',
    component: () => import('../views/hospitalDetail.vue')
  },
  // 首页
  {
    path: '/toutiao/index',
    name: 'toutiao/index',
    component: () => import('../views/toutiao/index.vue')
  }
  // 底部
  // {
  //   path: '/bottom',
  //   name: 'bottom',
  //   component: () => import('../views/bottom1.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
