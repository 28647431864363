import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import './assets/css/main.css'
import X2js from 'x2js'
import moment from 'moment'
import Loading from './components/loading.vue'
Vue.component('v-loading', Loading)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
Vue.prototype.$moment = moment
Vue.use(Vant)
Vue.config.productionTip = false
Vue.prototype.$x2js = new X2js()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
